import React from "react";
import { useColorMode } from "theme-ui";
import github from "../../images/github.svg";
import linkedin from "../../images/linkedin.svg";
import papers from "../../images/papers.svg";
import siteConfig from "../../../data/SiteConfig";
/** @jsx jsx */
import { Styled, jsx } from "theme-ui";

const icons = [
  { label: "linkedin", icon: linkedin },
  { label: "github", icon: github },
  { label: "papers", icon: papers }
];

const AvatarLinks = props => { 
  const { userLinks } = siteConfig;
  const { size } = props;

  const newUserLinks = [];
  icons.forEach(element => {
    const { icon } = element;
    userLinks.forEach(link => {
      const { label } = link;
      const { url } = link;
      if (label === element.label) {
        newUserLinks.push({
          label: label,
          url: url,
          icon: icon
        });
      }
    });
  });

  let iconSize = "30px";
  if (size === "small") {
    iconSize = "20px";
  }

  const [colorMode, setColorMode] = useColorMode();
  const isDark = colorMode === `dark`
  const toggleColorMode = e => {
    setColorMode(isDark ? `light` : `dark`)
  }
  let color = `white`; 
  if (isDark) {
    color = `highlight`
  }
  
  const iconsAndLinks = (
    <div sx={{ display: `flex` }}>
      {newUserLinks.map(element => {
        return (
          <div key={element.url} sx={{ mx: 1 }}>
            <a href={element.url} target="_blank">
              <img
                src={element.icon}
                sx={{
                  width: iconSize,
                  bg: color,
                  borderRadius: "5px",
                  p: 1,
                  ":hover": { bg: `muted` }
                }}
              />
              <div sx={{ opacity: `0`, position: `relative`, borderRadius: `5px`, textDecoration: `none`, fontSize: 14,
                  color: `primary`, textTransform: `capitalize`,
                ":hover": { opacity: `0.75`, bg: `muted`, textDecoration: `none`}
              }}>
                {element.label}
              </div>
            </a>
          </div>
        );
      })}
    </div>
  );

  return (
    <div sx={{ margin: `auto`, textAlign: `center`, display: `table`}}>
      {iconsAndLinks}
    </div>
  );
};
export default AvatarLinks;
