import React from "react";
import Helmet from "react-helmet";
import config from "../../data/SiteConfig";
import NavMenu from "../components/NavMenu";

/** @jsx jsx */
import { Styled, jsx } from "theme-ui";

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <Styled
        sx={{
          fontFamily: `body`,
          m: `auto`,
          maxWidth: `container`,
          width: ['90%']
        }}
      >
        <Helmet>
          <meta name="description" content={config.siteDescription} />
          <html lang="en" />
        </Helmet>

        <NavMenu menuLinks={config.menuLinks} />

        {children}

        <Styled sx={{ marginTop: `50px` }}> 
          <footer sx={{ textAlign: `center`, position: `auto` }}>
            © {(new Date().getFullYear())}
          </footer>
        </Styled>
        
      </Styled>
    );
  }
}
